<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 pt-5 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Подтверждение логина
          </b-card-title>
          <b-card-text class="mb-2">
            Введите код, высланный вам на e-mal или телефон
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Код"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="code"
                    :state="errors.length > 0 ? false:null"
                    :disabled="loading"
                    name="forgot-password-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                :disabled="loading"
                block
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-50"
                  />
                  <span>Подтвердить</span>
                </div>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Вход
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText,
  BImg, BForm, BFormGroup, BFormInput, BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      code: '',
      sideImg: require('@/assets/images/pages/vestabg.jpg'),
      // validation
      required,
      email,
      loading: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/vestabg.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.loading = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt.verifyLogin({
            BuyerC: this.$route.params.id,
            xx_pass: this.code,
          }).then(response => {
            const { data } = response.data
            const { userData } = data

            if (data) {
              useJwt.setToken(data.accessToken)
              useJwt.setRefreshToken(data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$store.commit('app/SET_USER', userData)
              this.$store.commit('app/SET_LOGGED_IN', true)

              window.location.replace('/')
            }
            this.loading = false
          }).catch(error => {
            this.$refs.simpleRules.setErrors(error)
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
